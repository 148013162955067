import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { selectCurrentPage } from './productsSelector';
import { selectMerchantId } from '../merchant/merchantSelector';
import { getProducts } from '../../services/productsService';

const PRODUCTS_PER_PAGE = 9;

const initialState = {
  page: 1,
  values: [],
  pendingGetProducts: true,
  errorGettingProducts: null,
  showLoadMore: false,
};

export const getProductsThunk = createAsyncThunk(
  'productsSlice/GET_PRODUCTS',
  async (merchant_id, { rejectWithValue, dispatch, getState }) => {
    try {
      const state = getState();

      const page = selectCurrentPage(state);

      let merchantId = merchant_id;
      if (!merchantId) {
        merchantId = selectMerchantId(state);
      }

      if (!merchantId) {
        return rejectWithValue({ message: 'There is no merchant id' });
      }

      const response = await getProducts({ merchantId, page, elements: PRODUCTS_PER_PAGE });
      if (response.status === 200) {
        if (response.data && response.data.length > 0) {
          dispatch(addPage());
        }
        return response.data;
      }
      return rejectWithValue({ response });
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code that falls out of the range of 2xx
        console.log('Error getProductsThunk', {
          response: error.response,
          config: error.config,
        });
        return rejectWithValue({
          response: error.response,
          config: error.config,
        });
      } else if (error.request) {
        // The request was made but no response was received `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
        console.log('Error getProductsThunk', {
          request: error.request,
          config: error.config,
        });
        return rejectWithValue({
          request: error.request,
          config: error.config,
        });
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error getProductsThunk', {
          message: error.message,
          config: error.config,
        });
        return rejectWithValue({
          message: error.message,
          config: error.config,
        });
      }
    }
  },
);

export const ProductsSlice = createSlice({
  name: 'Products',
  initialState,
  reducers: {
    addPage: (state) => {
      state.page = state.page + 1;
    },
    firstPage: (state) => {
      state.page = 1;
      state.values = [];
    },
  },
  extraReducers: {
    [getProductsThunk.pending]: (state) => {
      state.pendingGetProducts = true;
      state.errorGettingProducts = null;
      state.showLoadMore = false;
    },
    [getProductsThunk.rejected]: (state, action) => {
      state.pendingGetProducts = false;
      state.errorGettingProducts = action.payload;
      state.showLoadMore = false;
    },
    [getProductsThunk.fulfilled]: (state, action) => {
      state.values.push(...action.payload);
      state.pendingGetProducts = false;
      state.errorGettingProducts = null;
      state.showLoadMore = action.payload.length === PRODUCTS_PER_PAGE;
    },
  },
});

export const { addPage, firstPage } = ProductsSlice.actions;

export default ProductsSlice.reducer;
