import React from 'react';
import { Breadcrumbs, Grid, Typography, Zoom } from '@mui/material';
import styles from './ProductContainer.module.css';
import ProductItem from './ProductItem';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsFetchingProducts } from '../redux/products/productsSelector';
import { useEffect } from 'react';
import { setVariant } from '../redux/variants/variantsSlice';

const ProductContainer = ({ products }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setVariant({
        variantIndex: null,
        productId: null,
      }),
    );
  }, [dispatch]);

  const isFetching = useSelector(selectIsFetchingProducts);

  if (isFetching && products?.length === 0)
    return (
      <div className={styles.centered}>
        <Grid
          maxWidth="1536px"
          container
          spacing={2}
          columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 1 }}
          style={{ marginLeft: 8 }}>
          <Breadcrumbs aria-label="breadcrumb" sx={{ margin: '20px 25px 20px' }}>
            <Typography color="#4d4d4d">Loading...</Typography>
          </Breadcrumbs>
        </Grid>
      </div>
    );

  if (products.length === 0)
    return (
      <div className={styles.centered}>
        <Grid
          maxWidth="1536px"
          container
          spacing={2}
          columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 1 }}
          style={{ marginLeft: 8 }}>
          <Breadcrumbs aria-label="breadcrumb" sx={{ margin: '20px 25px 20px', minHeight: '80vh' }}>
            <Typography color="#4d4d4d">There are no products in this category.</Typography>
          </Breadcrumbs>
        </Grid>
      </div>
    );

  return (
    <Grid
      maxWidth="1536px"
      container
      spacing={2}
      columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 3 }}
      className={styles.container}>
      {products &&
        products.map((product, index) => {
          return (
            <Zoom key={index} in={true} style={{ transitionDelay: `${index * 200}ms` }}>
              <Grid key={index} item xs={12} sm={6} md={4} lg={4}>
                <ProductItem key={product.id} product={product} isExtraction={false} />
              </Grid>
            </Zoom>
          );
        })}
    </Grid>
  );
};

export default ProductContainer;
